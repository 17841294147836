<template>
  <el-card shadow="never">
                  <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="180px">
                      、
  
  
                    
                      <!-- <el-form-item label="校准搜索关键词：" prop="update_keyword">
                          <el-input v-model="editForm.update_keyword" controls-position="right" placeholder="" clearable
                              class="input163" />
                      </el-form-item> -->
  
                      <el-form-item label="请选择地区：" prop="pid">
                          <el-select v-model="editForm.pid" @change="handleChangeProv1(editForm.pid)" placeholder='请选择省'
                              class="selectStyle mr-10 mb-20" clearable>
                              <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                                  :label="option.name"></el-option>
                          </el-select>
                          <el-select v-model="editForm.cname" @change="handleChangeCity1(editForm.cname)"
                              placeholder="请选择市" class="selectStyle mr-10 mb-20" clearable>
                              <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                                  :label="option.name"></el-option>
                          </el-select>
                          <el-select v-model="editForm.aname" @change="$forceUpdate(), handleDis(editForm.aname)"
                              placeholder="请选择区/县" class="selectStyle mr-10 mb-20" clearable>
                              <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                                  :label="option.name"></el-option>
                          </el-select>
                      </el-form-item>
  
                      <el-form-item label="采样点名称" width="200" prop="search_keyword">
                          <el-input style="width: 500px;" v-model="editForm.sampling_name" controls-position="right" placeholder="" clearable
                              class="input163" />
                      </el-form-item>
  
                      <el-form-item label="采样点地址" prop="search_keyword">
                          <el-input style="width: 500px;" v-model="editForm.sampling_address" controls-position="right" placeholder="" clearable
                              class="input163" />
                              
                               <el-button type="info" style="margin-left: 20px;">地图定位</el-button>
                              
                              
                      </el-form-item>
  
  
                      <el-form-item label="咨询电话" prop="search_keyword">
                          <el-input style="width: 500px;" v-model="editForm.sampling_phone" controls-position="right" placeholder="" clearable
                              class="input163" />
                      </el-form-item>
  
                      <el-form-item label="工作时间" prop="search_keyword">
                          <el-input style="width: 500px;" v-model="editForm.sampling_time" controls-position="right" placeholder="" clearable
                              class="input163" />
                      </el-form-item>
  
                      <el-form-item label="送样单位" prop="search_keyword">
                          <el-input  style="width: 500px;" v-model="editForm.sampling_unit" controls-position="right" placeholder="" clearable
                              class="input163" />
                      </el-form-item>
  
                      <el-form-item label="送样联系人" prop="search_keyword">
                          <el-input style="width: 500px;" v-model="editForm.sample_name" controls-position="right" placeholder="" clearable
                              class="input163" />
                      </el-form-item>
  
                      <el-form-item label="联系方式" prop="search_keyword">
                          <el-input style="width: 500px;" v-model="editForm.sample_phone" controls-position="right" placeholder="" clearable
                              class="input163" />
                      </el-form-item>
                      
                      <el-form-item label="送检医院" prop="search_keyword">
                          <el-input style="width: 500px;" v-model="editForm.sample_hospital" controls-position="right" placeholder="" clearable
                              class="input163" />
                      </el-form-item>
  
                      <el-form-item label="送检医院联系人及电话" prop="search_keyword">
                          <el-input style="width: 500px;" v-model="editForm.sample_hospital_name" controls-position="right" placeholder="" clearable
                              class="input163" />
                      </el-form-item>
                      <!-- <el-form-item label="用户叫单搜索关键词：" prop="search_keyword">
                          <el-input v-model="editForm.search_keyword" controls-position="right" placeholder="" clearable
                              class="input163" />
  
                      </el-form-item>
                      <el-form-item label="校准搜索关键词：" prop="update_keyword">
                          <el-input v-model="editForm.update_keyword" controls-position="right" placeholder="" clearable
                              class="input163" />
                      </el-form-item> -->
                  </el-form>
              </el-card>
</template>


<script>
import {
    mapGetters
} from "vuex";
export default {
    name: "addpage",
    watch: {
        $route() {
            //切换标签时
            this.handleRefreshTable();
        }
    },
    data() {
        return {
            table: {
                url: 'user/nucleicIndex',
                where: {}
            }, // 表格配置
            choose: [], // 表格选中数据
            showEdit: false, // 是否显示表单弹窗
            editForm: {}, // 表单数据
            editRules: { // 表单验证规则
                pid: [{
                    required: true,
                    message: '请选择省份',
                    trigger: 'change'
                }],
                sampling_name: [{
                    required: true,
                    message: '请输入采样点名称',
                    trigger: 'blur'
                }],
                sampling_address: [{
                    required: true,
                    message: '请输入采样点地址',
                    trigger: 'blur'
                }],
                sampling_phone: [{
                    required: true,
                    message: '请输入采样点电话',
                    trigger: 'blur'
                }],
                sampling_time: [{
                    required: true,
                    message: '请输入采样点工作时间',
                    trigger: 'blur'
                }],
                sampling_unit: [{
                    required: true,
                    message: '请输入核酸送样单位',
                    trigger: 'blur'
                }],
                sample_name: [{
                    required: true,
                    message: '请输入送样联系人姓名',
                    trigger: 'blur'
                }],
                sample_phone: [{
                    required: true,
                    message: '请输入送样联系方式',
                    trigger: 'blur'
                }],
                sample_hospital: [{
                    required: true,
                    message: '请输入送检医院',
                    trigger: 'blur'
                }],
                sample_hospital_name: [{
                    required: true,
                    message: '请输入送检医院联系人姓名及联系电话',
                    trigger: 'blur'
                }],
            },
            provArr: [],
            cityArr: [],
            districtArr: [],
            activeName: 'first',
            activeEdit: 'run-driver',
            pid: '',
            cid: '',
            aid: '',
            changePro: false,
            changeCity: false,
            changeArea: false,

        }
    },
    created() {


        let _this = this;
        this.$http.get('/common/province_list').then(res => {
            let data = JSON.parse(res.data)
            this.provArr = data
        })
        window.onresize = function () {
            _this.handleRefreshTable();
        };

    },
    computed: {
        ...mapGetters(["permission"]),
    },
    updated() {
        //数据改变时
        this.handleRefreshTable();
    },
    mounted() { },
    methods: {
      handleRefreshTable(){
        
      },


        /**
         *选择省
         **/
        handleChangeProv(e) {
            /** 获取被选省份的pid**/
            let pid = ''
            this.provArr.forEach(function (item) {
                if (item.pid == e) {
                    pid = item.pid
                }
            })
            /** 根据被选省份的pid获取省市下面的市**/
            this.$http.post('/common/city_list', {
                pid: pid
            }).then(res => {
                let data = JSON.parse(res.data)
                this.cityArr = data
                /** 选择省份清空市县**/
                this.table.where.cid = ''
                this.table.where.aid = ''
            })
        },
        /**
         *选择市
         **/
        handleChangeCity(e) {
            if (e) {
                /** 获取被选市的cid**/
                let cid = ''
                this.cityArr.forEach(function (item) {
                    if (item.cid == e) {
                        cid = item.cid
                    }
                })
                /** 根据被选市的cid获取市下面的县**/
                this.$http.post('/common/area_list', {
                    cid: cid
                }).then(res => {
                    let data = JSON.parse(res.data)
                    this.districtArr = data
                    /** 选择省份清空县**/
                    this.table.where.aid = ''
                })
            } else {
                // this.table.where.pid = ''
                this.table.where.aid = ''
            }
        },

        /**
         *修改省
         **/
        handleChangeProv1(e) {
            /** 获取被选省份的pid**/
            if (e) {
                let pid = ''
                let that = this
                this.changePro = true
                this.provArr.forEach(function (item) {
                    if (item.pid == e) {
                        pid = item.pid
                    }
                })
                /** 根据被选省份的pid获取省市下面的市**/
                this.$http.post('/common/city_list', {
                    pid: pid
                }).then(res => {
                    let data = JSON.parse(res.data)
                    that.cityArr = data
                    /** 选择省份清空市县**/
                    this.editForm.cid = ''
                    this.editForm.aid = ''
                    this.editForm.cname = ''
                    this.editForm.aname = ''
                })
            } else {
                this.editForm.pid = ''
                this.editForm.cid = ''
                this.editForm.aid = ''
                this.editForm.pname = ''
                this.editForm.cname = ''
                this.editForm.aname = ''
            }

        },
        /**
         *修改市
         **/
        handleChangeCity1(e) {
            if (e) {
                /** 获取被选市的cid**/
                let cid = ''
                this.changeCity = true
                this.cityArr.forEach(function (item) {
                    if (item.cid == e) {
                        cid = item.cid
                    }
                })
                /** 根据被选市的cid获取市下面的县**/
                this.$http.post('/common/area_list', {
                    cid: cid
                }).then(res => {
                    let data = JSON.parse(res.data)
                    this.districtArr = data
                    /** 选择省份清空县**/
                    this.editForm.aid = ''
                    this.editForm.aname = ''
                })
            } else {
                // this.editForm.cname = ''
                this.editForm.aid = ''
                this.editForm.aname = ''
            }
        },
        handleDis() {
            this.changeArea = true
        },
        /**
         * 显示编辑
         */
        edit(row) {
            this.pid = row.pid
            this.cid = row.cid
            this.aid = row.aid

            this.editForm = row
            this.editForm.pid = row.pid
            this.editForm.cname = row.cid;
            this.editForm.aname = row.aid;
            this.showEdit = true;

            if (this.editForm.pid != '') {
                this.$http.post('/common/city_list', {
                    pid: this.editForm.pid
                }).then(res => {
                    let cdata = JSON.parse(res.data)
                    this.cityArr = cdata
                })
            }
            if (this.editForm.cid != '') {
                this.$http.post('/common/area_list', {
                    cid: this.editForm.cid
                }).then(res => {
                    let adata = JSON.parse(res.data)
                    this.districtArr = adata
                })
            }

            // this.$http.get('/limitnumbers/info?id=' + row.id).then(res => {
            //   if (res.data.code === 0) {
            //     if(res.data.data.type==1){
            //       res.data.data.type='出租车'
            //     }else  if(res.data.data.type==2){
            //       res.data.data.type='拼车'
            //     }else if(res.data.data.type==3){
            //       res.data.data.type='帮我买'
            //     }else if(res.data.data.type==4){
            //       res.data.data.type='取送件'
            //     }else if(res.data.data.type==5){
            //       res.data.data.type='超市'
            //     }else {
            //       res.data.data.type='叫餐'
            //     }
            //     res.data.data.pid=res.data.data.pname
            //     res.data.data.cid=res.data.data.cname
            //     res.data.data.aid=res.data.data.aname
            //     this.editForm = res.data.data;
            //     this.showEdit = true;
            //     //console.log( this.editForm)
            //   } else {
            //     this.$message.error(res.data.msg);
            //   }
            // }).catch(e => {
            //   this.$message.error(e.message);
            // });
        },
        /**
         * 保存编辑
         */
        save() {
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    const loading = this.$loading({
                        lock: true
                    });
                    //console.log(this.editForm)
                    // if (this.changePro) {
                    // 	this.editForm.pid = this.editForm.pid
                    // }
                    if (this.changeCity) {
                        this.editForm.cid = this.editForm.cname
                    }
                    if (this.changeArea) {
                        this.editForm.aid = this.editForm.aname
                    }
                    //console.log(this.editForm)
                    this.$http.post('/user/save_keyword', this.editForm).then(res => {
                        loading.close();
                        if (res.data.code === 0) {
                            this.showEdit = false;
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                            this.$refs.table.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        loading.close();
                        this.$message.error(e.message);
                    });
                } else {
                    return false;
                }
            });
        },
        /**
         * 刪除(批量刪除)
         */
        remove(row) {
            //console.log(row)
            if (!row) { // 批量删除
                if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
                let ids = this.choose.map(d => d.id);
                this.$confirm('确定要删除选中的设置吗?', '提示', {
                    type: 'warning'
                }).then(() => {
                    const loading = this.$loading({
                        lock: true
                    });
                    this.$http.post('/user/nucleicDel', {
                        id: ids
                    }).then(res => {
                        loading.close();
                        if (res.data.code === 0) {
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                            this.$refs.table.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        loading.close();
                        this.$message.error(e.message);
                    });
                }).catch(() => 0);
            } else { // 单个删除
                this.$confirm('确定要删除选中的设置吗?', '提示', {
                    type: 'warning'
                }).then(() => {
                    const loading = this.$loading({
                        lock: true
                    });
                    this.$http.post('/user/nucleicDel', {
                        id: row.id
                    }).then(res => {
                        loading.close();
                        if (res.data.code === 0) {
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                            this.$refs.table.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        loading.close();
                        this.$message.error(e.message);
                    });
                })
            }
        },
    }
}
</script>

<style scoped>
.ele-block>>>.el-upload,
.ele-block>>>.el-upload-dragger {
    width: 100%;
}

.ele-block>>>.el-upload,
.ele-block>>>.el-upload-dragger {
    width: 100%;
}

.driverForm /deep/.el-form-item__content {
    margin-left: 0 !important
}

/deep/.el-tabs__nav {
    margin-left: 20px
}

.prompt {
    color: #FF0000;
    font-size: 14px;
}
</style>